import styled from "styled-components";
import { motion } from "framer-motion";
// globalstyles
import GlobalStyles from "../../theme/GlobalStyles";

export const HomeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3%;
  margin-top: 2%;
  .ContainereButtonsHomeS{
    cursor: pointer;
  }
.Pruebats svg{
  height: 700px !important;
}
  // Mobil Frist
  @media screen and (min-width: 200px) {
  }
  @media screen and (min-width: 720px) {
  }
  @media screen and (min-width: 900px) {
  }
  @media screen and (min-width: 1200px) {
  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
    // DESK
  }
  @media screen and (min-width: 930px) and (orientation: landscape) {
  }
`;
export const BodyHome = styled.div`
  width: 100%;
  margin: auto;
  // Mobil Frist
  @media screen and (min-width: 200px) {
  }
  @media screen and (min-width: 720px) {
  }
  @media screen and (min-width: 900px) {

  }
  @media screen and (min-width: 1200px) {
    
  }

  // RESPONSIVE X MAX
  @media screen and (max-width: 1300px) {
    transform: scale(0.8);
    margin-top: -50px;
  }
  @media screen and (max-width: 1052px) {
    transform: scale(0.7)
  }
  @media screen and (max-width: 938px) {
    margin-top: -80px;
    transform: scale(0.6)
  }
  @media screen and (max-width: 838px) {
    transform: scale(0.7);
    margin-top: 0px;
  }
  @media screen and (max-width: 1417px) {
    transform: scale(0.9);
    margin-top: 0px;
  }
  @media screen and (max-width: 1283px) {
    transform: scale(0.8);
    margin-top: 0px;
  }
  @media screen and (max-width: 1111px) {
    transform: scale(0.7);
    margin-top: 0px;
  }
  @media screen and (max-width: 1000px) {
    transform: scale(0.6);
    margin-top: 0px;
  }
  @media screen and (max-width: 837px) {
    transform: scale(1);
    margin-top: 0px;
  }
  

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
    // DESK
  }
  @media screen and (min-width: 930px) and (orientation: landscape) {
    margin-right: 3%;
  }
`;

export const ContainerIniciar = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    .ContainerCopyHome{
        color: #403F3F;
        font-weight: bold;
    }
    .ContainerButtonIniciar{
      display: flex;
      flex-direction: row;
      position: relative;
      justify-content: space-between;
    min-width: 40px;
    width: 269px;
    min-height: 17px;
    border-radius: 16px;
    background-clip: inherit;
    background-image: linear-gradient(rgb(63, 52, 139), rgb(74, 51, 140), rgb(84, 50, 141), rgb(94, 48, 141), rgb(103, 46, 141));
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .ContainerButtonIniciar:hover{
      background: rgb(153, 38, 144);
    }
    .ContainerButtonIniciarText{
      flex:1;
      cursor: pointer;
    }
    .ContainerButtonIniciarText p{
      color: #fff;
      font-weight: bold;
      text-align: center;

    }
    .ContainerButtonIniciarImg{
      margin-right: 10px;
    }
  // Mobil Frist
  @media screen and (min-width: 200px) {
    flex-direction: column;
    justify-content: space-around;
    .ContainerCopyHome{
       font-size: 12px;
    }
    .ContainerButtonIniciar{
      margin-top: 10px;
    }
  }
  @media screen and (min-width: 720px) {
    .ContainerButtonIniciar{
      margin-bottom: 10px;
    }
  }
  @media screen and (min-width: 900px) {
    flex-direction: row;
    .ContainerCopyHome{
       font-size: 17px;
    }
    .ContainerButtonIniciar{
      margin-bottom: 10px;
    }
  }
  @media screen and (min-width: 1200px) {
  }

  // LANSCAPE Mobil
  @media screen and (min-width: 838px) and (orientation: landscape) {
    // DESK
  }
  @media screen and (min-width: 930px) and (orientation: landscape) {
  }
`;
