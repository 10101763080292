import React from "react";
import { HomeContainer, ContainerIniciar,BodyHome } from "./Homestyles";

// images
import DefinicionImage from "../../assets/images/menu/Topmenu/definicionestrategicadesk.svg";
import IdeacicionImage from "../../assets/images/menu/Topmenu/IdeacionyplandenegocioDesk.svg";
import DesarrolloImage from "../../assets/images/menu/Topmenu/desarrolloDesk.svg";
import FabricacopmImage from "../../assets/images/menu/Topmenu/fabricacionDesk.svg";
import SeguimientoImage from "../../assets/images/menu/Topmenu/SeguimientoDesk.svg";
import Todos from "./Marcos/Todos";
import TodosMobil from "./Marcos/TodosMobile";
import ArtesMobil from "./Marcos/ArtesMobile";
import ComboMobil from "./Marcos/ComboMobil";
import ExhibicionMobil from "./Marcos/ExhibicionMobile";
import ReplicaMobil from "./Marcos/ReplicaMobile";
import IniciativaIncrementalMobil from "./Marcos/IniciativaIncrementalMobile";
import IniciativaMobil from "./Marcos/IniciativaMobile";
import ImportacionesMobil from "./Marcos/ImportacionesMobile";
import DefinicionMobil from "./Marcos/DefinicionMobile";
import IdeacionMobil from "./Marcos/IdeacionMobile";
import DesarrolloMobil from "./Marcos/DesarrolloMobile";
import FabricacionMobil from "./Marcos/FabricacionMobile";
import SeguimientoMobil from "./Marcos/SeguimientoMobile";
import IniciarArrow from "../../assets/images/Arrows.svg";
import Artes from "./Marcos/Artes";
import Combo from "./Marcos/Combo";
import Exhibicion from "./Marcos/Exhibicion";
import Replica from "./Marcos/Replica";
import IniciativaDisruptiva from "./Marcos/IniciativaDiscriptiva";
import IniciacionIncremental from "./Marcos/IniciacionIncremental";
import Importaciones from "./Marcos/Importaciones";
import DefinicionEstrateigca from "./Marcos/DefinicionEstrategica";
import Ideacion from "./Marcos/Ideacion";
import Desarrollo from "./Marcos/Desarrollo";
import Fabricacion from "./Marcos/Fabricacion";
import Seguimiento from "./Marcos/Seguimiento";
import { useNavigate } from 'react-router-dom';
import useAxios from "../../api/useAxios";

const HomeComponent = ({
  HanlderSelectRouter,
  RutaSeleccionada,
  windowSize,
  RutaFooter,
  Tipo
}) => {
  const apiUrl = process.env.REACT_APP_BASE_URL;
      const navigate = useNavigate()
 



  const HandlerIniciar = ( ) =>{
    if(RutaFooter === undefined){
    } else {
      if(RutaSeleccionada === "DEFINICIÓN ESTRATÉGICA"){
        navigate("/definicion-estrategica", { replace: true });
      } else if (RutaSeleccionada === "IDEACIÓN Y PLAN DE NEGOCIO"){
        navigate("/ideacion", { replace: true });
      } else if (RutaSeleccionada === "DESARROLLO"){
        navigate("/desarrollo", { replace: true });
      } else if (RutaSeleccionada === "FABRICACIÓN"){
        navigate("/fabricacion", { replace: true });
      } else if (RutaSeleccionada === "SEGUIMIENTO"){
        navigate("/seguimiento", { replace: true });
      } else{
        if (RutaFooter[0].nombre === "DEFINICIÓN ESTRATÉGICA") {
          navigate("/definicion-estrategica");
        } else if (RutaFooter[0].nombre === "IDEACIÓN Y PLAN DE NEGOCIOS") {
          navigate("/ideacion", { replace: true });
        } else if (RutaFooter[0].nombre === "DESARROLLO") {
          navigate("/desarrollo", { replace: true });
        } else if (RutaFooter[0].nombre === "FABRICACIÓN") {
          navigate("/fabricacion", { replace: true });
        } else if (RutaFooter[0].nombre === "SEGUIMIENTO") {
          navigate("/seguimiento", { replace: true });
        }
      }
    }
    
  }
  return (
    <BodyHome>
      {windowSize.width <= 837 ? (
        <div style={{display: 'flex', justifyContent: 'center'}} className="Pruebats">
          {RutaSeleccionada === "TODOS" ? (
            <TodosMobil />
          ) : RutaSeleccionada === "ARTES" ? (
            <ArtesMobil />
          ) : RutaSeleccionada === "COMBO" ? (
            <ComboMobil />
          ) : RutaSeleccionada === "EXHIBICIÓN" ? (
            <ExhibicionMobil />
          ) : RutaSeleccionada === "RÉPLICA" ? (
            <ReplicaMobil />
          ) : RutaSeleccionada === "INICIATIVA DISRUPTIVA" ? (
            <IniciativaMobil />
          ) : RutaSeleccionada === "INICIATIVA INCREMENTAL" ? (
            <IniciativaIncrementalMobil />
          ) : RutaSeleccionada === "IMPORTACIONES" ? (
            <ImportacionesMobil />
          ) : RutaSeleccionada === "DEFINICIÓN ESTRATÉGICA" ? (
            <DefinicionMobil />
          ) : RutaSeleccionada === "IDEACIÓN Y PLAN DE NEGOCIO" ? (
            <IdeacionMobil />
          ) : RutaSeleccionada === "DESARROLLO" ? (
            <DesarrolloMobil />
          ) : RutaSeleccionada === "FABRICACIÓN" ? (
            <FabricacionMobil />
          ) : RutaSeleccionada === "SEGUIMIENTO" ? (
            <SeguimientoMobil />
          ) : (
            <TodosMobil />
          )}
        </div>
      ) : (
        <HomeContainer>
          <div>
            <div
              className="ContainereButtonsHomeS"
              onClick={() => HanlderSelectRouter("DEFINICIÓN ESTRATÉGICA",'')}
            >
              <img width={250} src={DefinicionImage} alt="DefinicionIcon" />
            </div>
            <div
              className="ContainereButtonsHomeS"
              onClick={() => HanlderSelectRouter("IDEACIÓN Y PLAN DE NEGOCIO",'')}
            >
              <img width={250} src={IdeacicionImage} alt="IdeacicionIcon" />
            </div>
            <div
              className="ContainereButtonsHomeS"
              onClick={() => HanlderSelectRouter("DESARROLLO",'')}
            >
              <img width={250} src={DesarrolloImage} alt="DesarrolloIcon" />
            </div>
            <div
              className="ContainereButtonsHomeS"
              onClick={() => HanlderSelectRouter("FABRICACIÓN",'')}
            >
              <img width={250} src={FabricacopmImage} alt="IDEACIÓNICon" />
            </div>
            <div
              className="ContainereButtonsHomeS"
              onClick={() => HanlderSelectRouter("SEGUIMIENTO",'')}
            >
              <img width={250} src={SeguimientoImage} alt="SeguimientoIcon" />
            </div>
          </div>
          <div>
            {RutaSeleccionada === "TODOS" ? (
              <Todos />
            ) : RutaSeleccionada === "ARTES" ? (
              <Artes />
            ) : RutaSeleccionada === "COMBO" ? (
              <Combo />
            ) : RutaSeleccionada === "EXHIBICIÓN" ? (
              <Exhibicion />
            ) : RutaSeleccionada === "RÉPLICA" ? (
              <Replica></Replica>
            ) : RutaSeleccionada === "INICIATIVA DISRUPTIVA" ? (
              <IniciativaDisruptiva></IniciativaDisruptiva>
            ) : RutaSeleccionada === "INICIATIVA INCREMENTAL" ? (
              <IniciacionIncremental></IniciacionIncremental>
            ) : RutaSeleccionada === "IMPORTACIONES" ? (
              <Importaciones></Importaciones>
            ) : RutaSeleccionada === "DEFINICIÓN ESTRATÉGICA" ? (
              <DefinicionEstrateigca></DefinicionEstrateigca>
            ) : RutaSeleccionada === "IDEACIÓN Y PLAN DE NEGOCIO" ? (
              <Ideacion></Ideacion>
            ) : RutaSeleccionada === "DESARROLLO" ? (
              <Desarrollo></Desarrollo>
            ) : RutaSeleccionada === "FABRICACIÓN" ? (
              <Fabricacion></Fabricacion>
            ) : RutaSeleccionada === "SEGUIMIENTO" ? (
              <Seguimiento></Seguimiento>
            ) : (
              <Todos />
            )}
          </div>
        </HomeContainer>
      )}

      <ContainerIniciar
       style={Tipo === "Modal" ? {display: 'none'}  : {marginBottom: 0}}
      >
        <div className="ContainerCopyHome">
          <p>* Todos aplican con maquila externa.</p>
        </div>
        <div 
        onClick={() => HandlerIniciar()}
        className="ContainerButtonIniciar">
          <div className="ContainerButtonIniciarText">
            <p>Iniciar</p>
          </div>
          <div className="ContainerButtonIniciarImg">
            <img src={IniciarArrow} alt="Arrows" />
          </div>
        </div>
      </ContainerIniciar>
    </BodyHome>
  );
};

export default HomeComponent;
